import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../vuex/store'
import Login from '../components/layout/Auth/login.vue'
import forgotPassword from '../components/layout/Auth/forgotPassword.vue'
import Main from '../components/Main.vue'
import Main2 from '../components/Main2.vue'
import vagas from '../components/layout/content/vagas/contentVagas.vue'
import detailsVaga from '../components/layout/content/vagas/detailsVaga.vue'
import vagaCreate from '../components/layout/content/vagas/vagaCreate.vue'
import vagaEdit from '../components/layout/content/vagas/vagaEdit.vue'
import vagasNotAproved from '../components/layout/content/vagas/vagasNotAproved.vue'
import concursos from '../components/layout/content/concursos/contentConcursos.vue'
import detailsConcurso from '../components/layout/content/concursos/detailsConcurso.vue'
import concursoCreate from '../components/layout/content/concursos/concursoCreate.vue'
import concursoEdit from '../components/layout/content/concursos/concursoEdit.vue'
import concursosNotAproved from '../components/layout/content/concursos/concursoNotAproved.vue'
import concursosGeneralViews from '../components/layout/content/concursos/concursosGeneralViews.vue'
import usersAdmin from '../components/layout/users/admin/usersAdmin.vue'
import empregosUsers from '../components/layout/users/empregos/empregosUsers.vue'
import empresasUsers from '../components/layout/users/empresas/empresasUsers.vue'
import changePassword from '../components/layout/account/changePassword.vue'
import publicity from '../components/layout/content/publicity/publicityComponent.vue'
import contentsComponent from '../components/layout/content/general/ContentsComponent.vue'
import paymentPlanComponent from '../components/layout/content/general/PaymentPlansComponent.vue'
import CategoriaComponent from '../components/layout/content/general/CategoriasComponent.vue'
import empresaComponent from '../components/layout/users/empresas/empresaComponent.vue'
import createEmpresa from '../components/layout/users/empresas/empresaCreate.vue'
import empresasDetails from '../components/layout/users/empresas/empresaDetails.vue'
import empresaEdit from '../components/layout/users/empresas/empresaEdit.vue'
import empresaNotAproved from '../components/layout/users/empresas/empresaNotAproved.vue'
import empresasViews from '../components/layout/users/empresas/empresasViews.vue'
import empresasLeads from '../components/layout/users/empresas/empresasLeads.vue'
import mpesaLog from '../components/layout/users/empresas/mpesaLog.vue'
import subscriptionPageLog from '../components/layout/users/empresas/subsciptionPageLog.vue'
import empresasDetailsLogs from '../components/layout/users/empresas/empresasDetailsLogs.vue'
import careerComponent from '../components/layout/career/careerComponent.vue'
import careerDetails from '../components/layout/career/careerDetails.vue'
import careerCreate from '../components/layout/career/careerCreate.vue'
import careerEdit from '../components/layout/career/careerEdit.vue'
import paymentLogs from '../components/layout/payments/paymentLogs.vue'
import dashboard from '../components/layout/dashboard/dashboard.vue'
import recoveryPassword from '../components/layout/Auth/recoveryPassword.vue'
Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'admin.login',
		component: Login
	},
	{
		path: '/forgotpassword',
		name: 'admin.forgotpassword',
		component: forgotPassword
	},
	{
		path: '/recovery-password/:token',
		name: 'admin.recovery-password',
		component: recoveryPassword,
		props: true
	},
	{
		path: '/dashboard',
		// name: 'admin.dashboard',
		meta: {auth: true},
		component: Main,
		children: [
			//dashboard
			{path: '/dashboard', name: 'admin.dashboard', component: dashboard},
			//vagas routes
			{path: '/vagas', name: 'admin.vagas',component: vagas,},
			{path: '/vagas/:id/show', name: 'admin.vagas.show',component: detailsVaga, props: true},
			{path: '/vagas/create', name: 'admin.vagas.create', component: vagaCreate},
			{path: '/vagas/:id/edit', name: 'admin.vagas.edit', component: vagaEdit, props: true},
			{path: '/vagas/to-aprove', name: 'admin.vagas-toaprove', component: vagasNotAproved},
			
			//concursos routes
			{path: '/concursos', name: 'admin.concursos',component: concursos},
			{path: '/concursos/:id/show', name: 'admin.concursos.show',component: detailsConcurso, props: true},
			{path: '/concursos/create', name: 'admin.concursos.create', component: concursoCreate},
			{path: '/concursos/:id/edit', name: 'admin.concursos.edit', component: concursoEdit, props: true},
			{path: '/concursos/to-aprove', name: 'admin.concursos-toaprove', component: concursosNotAproved},
			{path: '/concursos/views', name: 'admin.concursos-views', component: concursosGeneralViews},
			//User administration routes
			{path: '/admin-users', name: 'admin.admin-users', component: usersAdmin},
			//change password route
			{path: '/user/change-password', name: 'admin.change-password', component: changePassword},
			
			//empresas
			{path: '/empresas', name: 'admin.empresas', component: empresaComponent},
			{path: '/empresas/novas-empresas', name: 'admin.empresas.not-aproved', component: empresaNotAproved},
			{path: '/empresas/create-empresa', name: 'admin.empresas.create', component: createEmpresa},
			{path: '/empresas/:id/view', name: 'admin.empresas.view', component: empresasDetails, props: true},
			{path: '/empresas/:id/edit', name: 'admin.empresas.edit', component: empresaEdit, props: true},
			{path: '/empresas/views', name: 'admin.empresas.views', component: empresasViews},
			{path: '/empresas/leads', name: 'admin.empresas.leads', component: empresasLeads},
			{path: '/empresas/details', name: 'admin.empresas.details', component: empresasDetailsLogs},

			//career pages
			{path: '/careers', name: 'admin.careers', component: careerComponent},
			{
				path: '/careers/:slug',
				component: careerDetails,
				name: 'site.careers_page.details',
				props: true
			},
			{path: '/careers/create-page', name: 'admin.careers.create', component: careerCreate},
			{path: '/careers/edit-page/:id', name: 'admin.careers.edit', component: careerEdit, props: true},

			//logs routes
			{path: 'mpesa', name: 'admin.mpesa', component: mpesaLog},
			{path: 'subscricao-logs', name: 'admin.subscription', component: subscriptionPageLog}
		]
	},
	{
		path: '/admin',
		name: 'admin.dash2',
		meta: {auth: true},
		component: Main2,
		children: [
			// {path: '/admin/dashboard', name: 'admin.dash2', component: dashboard},
			//Users empregos / empresas routes
			{path: '/empregos-users', name: 'admin.empregos-users', component: empregosUsers},
			{path: '/empresas-users', name: 'admin.empresas-users', component: empresasUsers},
			//publicity routes
			{path: '/publicidade', name: 'admin.publicity', component: publicity},
			{path: '/allContents', name: 'admin.allContents', component: contentsComponent},
			{path: '/logs', name: 'admin.payments-logs', component: paymentLogs},
			{path: '/planos-pagamento', name: 'admin.payment-plans', component: paymentPlanComponent},
			{path: '/categorias', name: 'admin.categorias', component: CategoriaComponent}
		]
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})
router.beforeEach((to, from, next) => {
	const userAuthenticated = store.state.auth.authenticated
    if(to.matched.some(record => record.meta.auth)) {
      if (userAuthenticated) {
        next()
        return
      }
      next('/')
    } else {
      next()
    }
})
export default router
